<template>
    <div class="col-12 px-0">   
        <div class="bg-dark mb-4 h-25 pt-3 pb-3 pl-4 w-100" style="background: var(--color-broker-background);">
            <b-link :to="{name: 'Dashboard'}" class=" ml-auto">
                <img :src="logo" alt="Qsuite" style="max-height: 50px;">
             </b-link>
        </div>         
        <div class="container-fluid">
          
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-9">
                        <div class="row">
                            <div class="col-12 px-2 px-sm-3 pt-2">
                                <div class="">
                                    <h3 class="page-title font-b-6 mb-1" style="color: var(--color-broker);">O que você procura?</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <form @submit.prevent="filters">
                                    <div class="d-flex flex-wrap">
                                        <div class="col-12 col-lg-9 p-2 align-self-center">
                                            <div class="d-flex w-100">
                                                <input @keyup="filterByName" id="enterprise_name" class="form-control border-5" v-model="name" type="search" placeholder="Digite algo aqui" aria-label="Search" style="height: 44px; color:gray;      
                                                                                                                                    outline: 0;
                                                                                                                                    border: 0;
                                                                                                                                    border-radius:0px;
                                                                                                                                    background: #f6f6f7;
                                                                                                                                    border-bottom: 0.6px solid lightgray;">                                               
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-3 p-2  align-self-center">
                                            <div class="d-flex w-100">
                                                <input  @keyup="filterByCode" id="enterprise_code" class="form-control border-5" v-model="cod" type="search" placeholder="Código" aria-label="Search" style="height: 44px; color:gray;   
                                                                                                                                outline: 0;
                                                                                                                                border: 0;
                                                                                                                                border-radius:0px;
                                                                                                                                background: #f6f6f7;
                                                                                                                                border-bottom: 0.6px solid lightgray;">                                               
                                            </div>
                                        </div>                     
                                     </div>
									<div class="">
                                        <div class="d-flex flex-wrap">
                                            <div class="col-12 col-md-6 p-1 col-lg-3">
                                                <div class="form-group  mb-0 mb-0">                                           
                                                    <select class="form-control" id="enterprise_status" style="height: 48px !important;

                                                                            color:gray;    
                                                                            outline: 0;
                                                                            border: 0;
                                                                            border-radius:0px;
                                                                            background: #f6f6f7;
                                                                            border-bottom: 0.6px solid lightgray;">
                                                    <option value="null" selected="selected">Status</option>
                                                    <option v-for="item in enterprise_config_status" :value="item.id">{{item.name}}</option>
                                                </select>                                                     
                                            </div>
                                        </div> 

	                                       
                                    
                                        <div class="col-12 col-md-6 col-lg-3 p-1 m-0" v-if="enterprise_config_finalidade.length>=1">
                                            <div class="form-group  mb-0 mb-0">                                           
                                                <select class="form-control" id="finalidade" style="height: 48px !important;

                                                                        color:gray;  
                                                                            outline: 0;
                                                                            border: 0;
                                                                            border-radius:0px;
                                                                            background: #f6f6f7;
                                                                            border-bottom: 0.6px solid lightgray;
                                                                        "v-model="enterprise_id">
                                                    <option value="null" selected="selected">Tipo de Negócio</option>
                                                    <option v-for="item in enterprise_config_finalidade" :value="item.id">{{item.name}}</option>
                                                </select>                                                     
                                            </div>
                                        </div>                               <div class="col-12 col-md-6 col-lg-3 p-1 m-0">
                                            <div class="form-group  mb-0 mb-0">                                           
                                                <select class="form-control" id="type" style="height: 48px !important;
													color:gray;    
														outline: 0;
														border: 0;
														border-radius:0px;
														background: #f6f6f7;
														border-bottom: 0.6px solid lightgray;">
                                                    <option value="null" selected="selected">Tipo do Imóvel</option>
                                                    <option v-for="item in enterprise_type" :value="item.id">{{item.name}}</option>
                                                </select>                                                     
                                            </div>
                                        </div>  

									  <!-- <div class="col-12 col-md-6 col-lg-3 mt-1 p-2">
										<div class="form-group  mb-0 mb-0">        
											<label for="customRange3" style="text-transform: none;font-size: 14px;">Metragem ({{current_metragem}} m²)</label>
											<input type="range" @change="defineMetragem" class="form-range w-100" :min="min_area" :max="max_area" step="50" id="metragem">
										</div>
                                  	</div>  -->

								</div>
							  </div>
                             <div class="d-flex flex-wrap">
                          
																		 
																		     
                                            <div class="d-flex justify-content-around">

                                           
                                            <div class="d-flex" v-for="(item, index) in areaLabels">
                                                <b v-if="index  == 0" class="mr-2 float-left">{{min_area}}</b> 
                                                <p>{{item}}</p>
                                                <b v-if="index  == areaLabels.length -1" class="ml-2 float-left">{{max_area}}</b> 
                                                </div>
                                            </div>
                                          
                                                    
                                              
                                        </div> 
                                         
               

                                        <div class="col-12 col-md-6 col-lg-3">
                                           <button  style="background-color: var(--color-broker)" class="btn btn-primary"  type="submit"><i class="fa fa-search" aria-hidden="true"></i> BUSCAR</button>
                                        </div>     
                                </form>
                                
                            </div>
                            
                            <div class="col-12 p-2 d-sm-none d-none d-lg-block d-sm-none d-md-none"  v-if="carregando=='não'">
                                <div class="col-12 pt-2 mb-3">
                                    <!-- <h2 class="mb-1 font-weight-bold  title-dash-ar" style="color: var(--color-broker);">Veja o que preparamos para você</h2> -->
                                </div>

                                <div class="d-flex flex-wrap" v-if="enterprise_filters.length < 1">
                                    <div class="col-lg-3 col-md-6 p-2" v-if="item.image_principal != null" v-for="item in enterprises">
                                        <div>
                                          <b-link :to="{name: 'DocumentsBroker', params: {id: item.id}}" class="float-right card-body-list-link position-relative">

                                            <div class="card">
                                                <div class="">
                                                <div class="foto  cover  position-relative">
                                                    <img class="card-img-top" :src="item.image_principal" alt="Qsuíte" style="height: 400px; object-fit: cover; ">
                                                    <p class="status m-0"><b>{{item.status.name}}</b></p>
                                                </div>    

                                                <p v-if="item.effect_phrase!=''">{{item.effect_phrase}}</p>                                            
                                                    <p class="mt-3 ms-3 fs-4" style="font-size:14px;color:black"><b >{{item.name}}</b></p>
                                                    <!-- <div v-for="config in item.configs" v-if="item.configs.length>=1">
                                                      <p class="mt-3 ms-3 fs-4 text-muted" style="font-size:13px;color:black" v-if="config.config.type=='QUARTO'"><b>{{config.config.name}} quarto(s)</b></p>
                                                      <p class="mt-3 ms-3 fs-4 text-muted" style="font-size:13px;color:black" v-if="config.config.type=='SUITE'"><b>{{config.config.name}} suite(s)</b></p>
                                                    </div> -->
                                                    <div class="d-flex">
                                                        <p class=" ms-3 fs-4 text-muted"  v-if="item.quartos!=''" style="font-size:13px;color:black"><b>{{item.quartos}} | </b></p> 
                                                        <p class=" ms-3 fs-4 text-muted" v-if="item.suites!=''" style="font-size:14px;color:black"><b> {{item.suites}}</b></p>
                                                        <p class=" ms-3 fs-4 ml-1 text-muted" v-if="item.metragens!=''" style="font-size:14px;color:black"><b> |  {{item.metragens}}</b></p>
                                                    </div> 
                                                    <!-- <p class="ms-3 fs-4 text-muted" style="font-size:13px;color:black"><b>Tipo: {{item.type.name}}</b></p> -->
                                                    <!-- <p class="mt-3 ms-3 fs-4 text-muted" v-if="item.area!=null" style="font-size:14px;color:black"><b>{{item.area}}</b></p> -->
                                                  
                                                </div>
                                               
                                            </div>
                                        </b-link>
                                        </div>
                                    </div>                                        
                                </div>

                                
                                <div class="d-flex flex-wrap" v-else>                                    
                                    <div class="col-lg-3 col-md-6 p-2" v-if="item.image_principal != null"  v-for="item in enterprise_filters">
                                        <div>
                                            <b-link v-if="item.image_principal != null" :to="{name: 'DocumentsBroker', params: {id: item.id}}" class="float-right card-body-list-link position-relative">

                                            <div class="card">
                                                <div class="">
                                                <div class="foto  cover  position-relative">
                                                    <img class="card-img-top" :src="item.image_principal" alt="Qsuíte" style="height: 400px; object-fit: cover; ">
                                                    <p class="status m-0"><b>{{item.status.name}}</b></p>
                                                </div>
                                                    <p class="mt-3 ms-3 fs-4" style="font-size:16px;color:black"><b >{{item.name}}</b></p>
                                                </div>
                                               
                                            </div>
                                        </b-link>
                            
                                    </div>                                        
                                </div>                          
                               </div>
                            </div>
                            <div class="d-flex mb-5 pb-5 justify-content-center w-100 flex-wrap mx-auto" v-else>
                                <div class="spinner-grow" style="width:71px; color:var(--color-broker); height:71px" role="status">
                                    <span class="visually-hidden"></span>
                                </div>
                                <br/>
                            </div>                           
                        </div>
                    </div>

    <template>
  <section class="container empreendimentos d-lg-none" v-if="enterprise_filters.length < 1">
    <h2>EMPREENDIMENTOS</h2>
    <div v-if="carregando=='não' && enterprises.length">
      <carousel
        :per-page="width < 500 ? 1 : isMobile ? 2 : 4"
        :spacePadding="1"
      >
        <slide v-for="item in enterprises" :key="item.id" v-if="item.image_principal != null">
          <b-link :to="{name: 'DocumentsBroker', params: {id: item.id}}" class="float-right card-body-list-link position-relative">
            <div class="card-emp">
                <div class="imagem-nome">
                <img :src="item.image_principal || item.image" :alt="item.name" />
                <span class="nome"> {{ item.name }} </span>
                </div>

                <!-- <p class="descricao">
                {{ item.description || item.name }}
                </p> -->
            </div>
         </b-link>
        </slide>
      </carousel>
    </div>

    <div v-if="carregando=='sim'">
        <div class="d-flex mb-5 pb-5 justify-content-center w-100 flex-wrap mx-auto">
            <div class="spinner-grow" style="width:71px; color:var(--color-broker); height:71px" role="status">
                <span class="visually-hidden"></span>
            </div>
            <br/>
        </div>  
    </div>
  </section>
  <section class="container empreendimentos d-lg-none" v-else>
    <h2>EMPREENDIMENTOS</h2>
    <div v-if="carregando=='não' && enterprise_filters.length">
      <carousel
        :per-page="width < 500 ? 1 : isMobile ? 2 : 4"
        :spacePadding="1"
      >

        <slide v-for="item in enterprise_filters" :key="item.id">
            <b-link  v-if="item.image_principal != null" :to="{name: 'DocumentsBroker', params: {id: item.id}}" class="float-right card-body-list-link position-relative">
                <div class="card-emp">
                    <div class="imagem-nome">
                    <img :src="item.image_principal || item.image" :alt="item.name" />
                    <span class="nome"> {{ item.name }} </span>
                    </div>

                    <!-- <p class="descricao">
                    {{ item.description || item.name }}
                    </p> -->
                </div>
            </b-link>
        </slide>
      </carousel>
    </div>

    <div v-if="carregando=='sim'">
      <carousel
        :per-page="width < 500 ? 1 : isMobile ? 2 : 4"
        :spacePadding="1"
      >
        <slide>
          <div class="card-emp">
            <div class="imagem-nome">
              <b-skeleton-img height="350px"></b-skeleton-img>
            </div>

            <p class="descricao mt-2">
              <b-skeleton animation="wave" width="50%"></b-skeleton>
            </p>
          </div>
        </slide>
        <slide>
          <div class="card-emp">
            <div class="imagem-nome">
              <b-skeleton-img height="350px"></b-skeleton-img>
            </div>

            <p class="descricao mt-2">
              <b-skeleton animation="wave" width="80%"></b-skeleton>
            </p>
          </div>
        </slide>
        <slide>
          <div class="card-emp">
            <div class="imagem-nome">
              <b-skeleton-img height="350px"></b-skeleton-img>
            </div>

            <p class="descricao mt-2">
              <b-skeleton animation="wave" width="30%"></b-skeleton>
            </p>
          </div>
        </slide>
        <slide>
          <div class="card-emp">
            <div class="imagem-nome">
              <b-skeleton-img height="350px"></b-skeleton-img>
            </div>

            <p class="descricao mt-2">
              <b-skeleton animation="wave" width="70%"></b-skeleton>
            </p>
          </div>
        </slide>
      </carousel>
    </div>
  </section>
</template>


                    <div class="col-12 col-md-12 col-lg-3 p-0">
                        <!-- <div class="col-12 p-2 p-sm-2">
                            <div class="col-12 pt-3">
                                <h2 class="mb-1 font-weight-medium title-dash-ar" style="color: var(--color-broker);">Meus leads</h2>
                            </div>
                            <div class="col-12 p-0">
                                <div class="bder-default" style="background-color: #fff;">
                                    <div class="col-12 px-3">
                                        <a href="?app=financeiro.php" title="">
                                            <div class="d-flex align-self-center py-3">
                                                <div class="align-self-center pr-3">
                                                    <div class="d-flex align-items-center text-center" style="height:60px; width:60px; border-radius:200px; background-color: #f6f6f7;">
                                                        <img src="@/assets/img/logo-gsuite.png" class="img-fluid p-2" alt="Qsuíte">
                                                    </div>
                                                </div>
                                                <div class="align-self-center">
                                                    <h5 class="mb-0 font-b-6" style="color:#949494; font-size:10px;">HUB COMPACT</h5>
                                                    <h5 class="mb-0 title-dash-ar-inc"><b>DIEGO SIQUEIRA</b></h5>
                                                    <p class="mb-0" style="color:#949494; font-size:12px;">61 99667-3409 | diego@cityinc.com.br</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-12 px-3" style="border-top: 1px #E7E7E7 solid;">
                                        <a href="?app=financeiro.php" title="">
                                            <div class="d-flex align-self-center py-3">
                                                <div class="align-self-center pr-3">
                                                    <div class="d-flex align-items-center text-center" style="height:60px; width:60px; border-radius:200px; background-color: #f6f6f7;">
                                                        <img src="@/assets/img/logo-gsuite.png" class="img-fluid p-2" alt="Qsuíte">
                                                    </div>
                                                </div>
                                                <div class="align-self-center">
                                                    <h5 class="mb-0 font-b-6" style="color:#949494; font-size:10px;">HUB COMPACT</h5>
                                                    <h5 class="mb-0 title-dash-ar-inc"><b>CAMILA SILVA</b></h5>
                                                    <p class="mb-0" style="color:#949494; font-size:12px;">61 99667-3409 | diego@cityinc.com.br</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-12 px-3" style="border-top: 1px #E7E7E7 solid;">
                                        <a href="?app=financeiro.php" title="">
                                            <div class="d-flex align-self-center py-3">
                                                <div class="align-self-center pr-3">
                                                    <div class="d-flex align-items-center text-center" style="height:60px; width:60px; border-radius:200px; background-color: #f6f6f7;">
                                                        <img src="@/assets/img/logo-gsuite.png" class="img-fluid p-2" alt="Qsuíte">
                                                    </div>
                                                </div>
                                                <div class="align-self-center">
                                                    <h5 class="mb-0 font-b-6" style="color:#949494; font-size:10px;">HUB COMPACT</h5>
                                                    <h5 class="mb-0 title-dash-ar-inc"><b>GUILHERME DIAS</b></h5>
                                                    <p class="mb-0" style="color:#949494; font-size:12px;">61 99667-3409 | diego@cityinc.com.br</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-12" style="border-top: 1px #E7E7E7 solid;">
                                        <div class="row">
                                            <div class="col-6 p-3 text-center" style="border-right: 1px #E7E7E7 solid;">
                                                <a href="?app=financeiro.php" title="">
                                                    <h5 class="mb-0 font-12 font-b-6" style="color:#aaa9a9">TODOS</h5>
                                                </a>
                                            </div>
                                            <div class="col-6 py-3">
                                                <a href="?app=financeiro.php" title="">
                                                    <div class="d-flex mx-auto align-items-center" style="width: max-content">
                                                        <h5 class="mb-0 font-12 font-b-6">ADD</h5>
                                                        <img src="@/assets/img/ico-qs-10.png" height="13" class="ml-2" alt="Qsuíte">
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
               
                        <div class="col-12 p-2 p-sm-2">
                            <div class="col-12 pt-3">
                                <h2 class="mb-1 font-weight-medium title-dash-ar font-weight-bold" style="color: var(--color-broker);">Feed Comercial</h2>
                            </div>
                            <div class="col-12 p-0">
                                <div v-if="carregandofeed=='sim'">
                                    <div class="d-flex mb-5 pb-5 justify-content-center w-100 flex-wrap mx-auto">
                                        <div class="spinner-grow" style="width:71px; color:var(--color-broker); height:71px" role="status">
                                            <span class="visually-hidden"></span>
                                        </div>
                                        <br/>
                                    </div>  
                                </div>
                                <div v-else>
                                    <div v-if="all_documents.length>=1" class="bder-default" v-for="(item, index) in all_documents" :key="item.id">
                                    <div v-if="item?.type?.name=='Tabela comercial do mês'">
                                        <a href="#" target="_blank" :href="item.path_name">
                                            <div class="d-flex align-self-center bder-default p-3 mt-2"  style="background-color: #fff;">                                           
                                                    <div class="align-self-center pr-3">
                                                        <div v-if="item.enterprise!=null && item.enterprise.image!=null"  class="d-flex align-items-center text-center" :style="`height:60px; width:60px; border-radius:200px; background-color: ${index ? '#fff' : '#f6f6f7'};`">
                                                            <img :src="item.enterprise.image" class="img-fluid p-2" alt="Qsuíte">
                                                            <!-- <h6 v-if="item.enterprise.image==null">{{item.enterprise.name}}</h6> -->
                                                        </div>
                                                    </div> 
                                                    <div class="align-self-center">
                                                        <h5 v-if="item.enterprise!=null" class="mb-0 title-dash-ar-inc"><b>{{item.enterprise.name}}</b></h5>
                                                        <p class="mb-0" style="color:#949494; font-size:14px;" v-if="item.description!=null">{{item.description}}</p>
                                                        <p class="mb-0" style="color:#949494; font-size:12px;" v-else>{{item.path_name}} {{item.created_at}}</p>
                                                    </div>
                                                
                                                    <!-- <div class="align-self-center ml-auto">
                                                        <img src="@/assets/img/ico-qs-07.png" class="" height="14" alt="Qsuíte">
                                                    </div> -->
                                                </div>                                       
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <!-- <div class="col-12">
                            <div class="bder-default p-4" style="background-color: var(--color-broker);">
                                <a href="?app=financeiro.php" title="">
                                    <div class="d-flex my-2">
                                        <div class="align-self-center pr-3">
                                            <img src="@/assets/img/ico-qs-09.png" height="28" alt="Qsuíte">
                                        </div>
                                        <div class="align-self-center">
                                            <h2 class="mb-0 font-weight-medium font-18 text-white">
                                                Avalie seu nível<br>
                                                de satisfação
                                            </h2>
                                            <p class="m-0 p-0 text-white" style="font-size: 9px;">Sua experiência aqui é muito importante para nós.</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div> -->
                    </div>
                    <div class="col-9 w-100 mx-auto p-2 p-sm-2">
                                <div class="col-12 py-2 text-center">
                                    <h3 class="font-28 font-b-6 mb-1" style="color: var(--color-broker);">{{theme.sentence}}</h3>
                                </div>
                                <div class="d-flex justify-content-center mx-auto w-100">                                    
                                    <div v-if="theme.image_desktop" class="d-none d-md-block pr-0 pr-lg-4">
                                        <img :src="theme.image_desktop" class="w-100 bder-default" title="Banner2">
                                    </div>
                                    <div v-if="(theme.image_mobile)" class="d-md-none d-flex">
                                        <img :src="theme.image_mobile" class="w-100 bder-default" title="Banner2">
                                    </div>
                                </div>
                            </div>
                </div>
            </div>
 
            <!-- <div  class="d-flex mt-5 w-100">      
                  <div class="col-lg-6 mt-3 text-left">       
                    <a href="https://r4marketing.com.br/politica-de-privacidade"class="mt-5" target="_blank">Política de Privacidade</a>                  
              
                </div> 
              <div class="col-lg-6 text-right">  
                    <img  src="/img/logo-gsuite.847b9730.png" width="100" alt="logo g suite">
                    <p> © Todos os direitos reservados. </p>
             </div>  
                  
            </div> -->

        </div>
            <footer class="footer text-center text-muted p-0">
        <div class="col-12 py-3">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-9 col-lg-10 text-center text-sm-left align-self-center mx-auto py-2">
                        © {{new Date(Date.now()).getFullYear()}} | QSuíte _Hub de integração de dados. Todos os direitos reservados. <a :href="'https://'+site+'/termos-de-uso'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de uso</a> | <a :href="'https://'+site+'/politica-de-privacidade'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Política de privacidade</a> | <a href="https://r4marketing.com.br/termos-de-uso" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de Uso</a>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 text-center text-sm-right align-self-center">
                        <a href="https://qsuite.com.br" target="_blank"><img src="@/assets/img/logo-gsuite.png" alt="Qsuite" style="max-height: 23px;"></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </div>
</template>
<script>

import ThemeService from "@/services/resources/ThemeService";
import EnterpriseFileBrokerServiceNoLogin from "@/services/resources/EnterpriseFileBrokerServiceNoLogin";
import EnterpriseFileServiceNoLogin from "@/services/resources/EnterpriseFileServiceNoLogin";
const serviceTheme = ThemeService.build();
const serviceEnterpriseBroker = EnterpriseFileBrokerServiceNoLogin.build();
const serviceEnterpriseFile = EnterpriseFileServiceNoLogin.build();

import { Carousel, Slide } from "vue-carousel";
  export default {
    name: "Dashboard",
    data() {
        return {
            enterprises: [],
            width: "",
            logo: "",
            link_landing_page: "",
            all_enterprises: [],
            site:"",
            carregandofeed: "não",
            enterprise_filters: [],
            enterprise_status: [],
						enterprise_diferences: [],
                        	enterprise_diferences2: [],
             enterprise_suites: [],
            enterprise_type: [],
            enterprise_config_metragem: [],
            enterprise_config_finalidade: [],
            enterprise_config_status: [],
            name: null,
            current_metragem: "",
            cod: "",
            areas: [],
            finalidade: [],
            areaLabels: [],
            min_area: "",
            max_area: "",
            enterprise_id: null,
            theme: {
                sentence: null,
                color: "",
                image_desktop: null,
                image_mobile: null
            },
            all_documents: [],
            carregando:'não'
        };
    },
    watch: {
        enterprise_id() {
         //   this.fetchEnterprises(this.enterprise_id ? {enterprise_id: this.enterprise_id} : null);
        },
      
    },

    methods: {
        filters(eve) {
            //let suites = document.getElementById("enterprise_suites").options[document.getElementById("enterprise_suites").selectedIndex].value;
            let status = ""
             let type =""
            let finalidade = ""
            if(document.getElementById("enterprise_status")!=null){
                status = document.getElementById("enterprise_status").options[document.getElementById("enterprise_status").selectedIndex].value;
            }  
            
            if(document.getElementById("finalidade")!=null){
                finalidade=document.getElementById("finalidade").options[document.getElementById("finalidade").selectedIndex].value
            }
            if(document.getElementById("type")!=null){
                type=document.getElementById("type").options[document.getElementById("type").selectedIndex].value
            }
           

            eve.preventDefault();             
            // this.enterprise_filters = this.enterprises.filter((enterprise) => {
            //     if (enterprise.area_enterprise <= document.getElementById("metragem").value) {
            //         return enterprise
            //     }
            // })

            if (finalidade !== "null") {
                this.enterprise_filters = this.enterprises.filter((enterprise) => {
                    if (enterprise.finalidade_id == finalidade && finalidade !== "null") {                    
                        return enterprise
                    }
                })
            }

            if (type !== "null") {
                this.enterprise_filters = this.enterprises.filter((enterprise) => {
                    if ( enterprise.type.id == type && type !== "null") {                       
                        return enterprise
                    }
                })
            }
            
            if (status !== "null") {
                this.enterprise_filters = this.enterprises.filter((enterprise) => {
                    if ( enterprise.status.id == status && status !== "null") {                      
                        return enterprise
                    }
                })
            }

        },
        defineMetragem() {
         //   this.current_metragem = document.getElementById("metragem").value
        },
        getMonth(index) {
            let months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ', ]
            return months[index - 1];
        },
        getDocuments() {
            let information ={
               tabela: 1,
               companyid : this.companyid
           };
           this.carregandofeed="sim"
            serviceEnterpriseFile.search(information)
            .then(resp => {
            
                
                let createdAtTreated;
                    console.log('r');
                     this.carregandofeed="não"
                       
                let all_documents_treated = resp.map(item => {
                        createdAtTreated = item.created_at.split('T')[0];
                        let month = createdAtTreated.split('-')[1];
                        let year = createdAtTreated.split('-')[0];

                        return {
                            ...item,
                            created_at: this.getMonth(month)+"/"+year[year.length-1]+year[year.length-2]
                        }
                    });
          console.log(resp);

                    this.all_documents = all_documents_treated;
                    console.log(this.all_documents);
            })

        },

        filterByName(eve) {
           
            console.log(document.getElementById("enterprise_name").value);
            this.enterprise_filters = this.enterprises.filter((enterprise) => {
                if (enterprise.name.toLowerCase().indexOf(document.getElementById("enterprise_name").value.toLowerCase()) != -1) {
                    return enterprise
                }
            })
        },

        filterByCode() {
            this.enterprise_filters = this.enterprises.filter((enterprise) => {
                if (enterprise.cod == parseInt(document.getElementById("enterprise_code").value)) {
                    return enterprise
                }
            })
        },

        fetchTheme(){
           
           let data = {
               domain: window.location.hostname
           };

           serviceTheme 
           .search(data)
           .then(resp => {
          
           
                
               resp.forEach((element, index) => {
                   switch(element.key){
                        case 'PORTAL_BROKER_BACKGROUND_DESKTOP_LOGGED':
                            this.theme.image_desktop = element.value;
                        break;
                        case 'PORTAL_BROKER_BACKGROUND_MOBILE_LOGGED':
                            this.theme.image_mobile = element.value;
                        break;
                        case 'PORTAL_BROKER_SENTENCE_LOGGED':
                        this.theme.sentence = element.value
                            break;
                        case "SETTINGS_LOGO":
                            this.logo = element.value;
                            break;
                    case 'PORTAL_BROKER_COMPONENT_COLOR':
                        this.theme.color = element.value
                            break;
                        case 'SETTINGS_DOMAIN':
                            this.theme.portal_cliente = element.value
                            break;
                      
                   }

                 
                });
                this.companyid = resp[0].company_id
               
               this.getDocuments();
               this.fetchEnterprises();
           })
          
       },

       fetchEnterprises(params = null){
          
           let information = {
               all: 1,
               companyid: this.companyid
           };

        //    if(!params) {
        //         serviceEnterpriseBroker
        //         .search(data)
        //         .then(resp => {
        //             this.all_enterprises = resp;                   
        //         });
        //    }
    this.carregando='sim'

           serviceEnterpriseBroker 
           .search(information ?? "")
           .then(resp => {
            this.carregando='não'
            resp[0].forEach((enterprise, index) => {
              //  enterprise.configs.forEach((configs) => {                  
                    resp[1].filter((configuracoes) => {

                        let quartos=enterprise.configs.filter((configuracao)=>{
                            console.log(configuracao);
                            if(configuracao.config.type=="QUARTO"){
                                return configuracao
                            }
                        })
                       
                       enterprise["quartos"] = ""
                        if (quartos.length==1){
                            enterprise.quartos = quartos[0].config.name + "quarto(s)"
                        }

                        if (quartos.length>1){
                            if(quartos[0].config.name < quartos[1].config.name){
                                enterprise.quartos = "de " + quartos[0].config.name + " a "+ quartos[1].config.name+ " quartos"
                            }else{
                                 enterprise.quartos = "de " + quartos[1].config.name + " a "+ quartos[0].config.name+ " quartos"
                            }                            
                        }


                         let suites=enterprise.configs.filter((configuracao)=>{
                            console.log(configuracao);
                            if(configuracao.config.type=="SUITE"){
                                return configuracao
                            }
                        })
                       
                       enterprise["suites"] = ""
                        if (suites.length==1){
                            enterprise.suites = suites[0].config.name + "suites(s)"
                        }

                        if (suites.length>1){
                            if(suites[0].config.name < suites[1].config.name){
                             enterprise.suites = suites[0].config.name + " ou "+ suites[1].config.name+ " suites"
                            }else{
                                enterprise.suites = suites[1].config.name + " ou "+ suites[0].config.name+ " suites"
                            }
                        }


                        let metragens=enterprise.configs.filter((configuracao)=>{
                            if(configuracao.config.type=="METRAGEM" && configuracoes.is_active == 1){
                                return configuracao
                            }
                        })
                       
                       enterprise["metragens"] = ""
                        if (metragens.length==1){
                            enterprise.metragens = metragens[0].config.name + "m²"
                        }

                        if (metragens.length>1){
                           
                            if(parseInt(metragens[0].config.name) < parseInt(metragens[1].config.name)){
                             enterprise.metragens = "de " + metragens[0].config.name + " a "+ metragens[1].config.name+ " m²"
                            }else{
                                enterprise.metragens = "de " + metragens[1].config.name + " a "+ metragens[0].config.name+ " m²"
                            }
                        }
                     
                        let config = enterprise.configs.filter((config) => { 
                            
                            if (configuracoes.id == config.config_id) {
                                return config
                            }
                        })[0]

                        if (config != null && config.config_id != null) {
                            console.log(config.config_id);
                            console.log(configuracoes.id);
                            if (configuracoes.type == "METRAGEM" && configuracoes.is_active == 1 && configuracoes.id == config.config_id) {
                                enterprise.metragem = parseInt(configuracoes.name)

                                       
                                let isDuplicate = this.enterprises.includes(this.enterprises[index]);   
                                console.log(isDuplicate);                     
                                    if (isDuplicate == false) {                                
                                        this.enterprises[this.enterprises.length] = enterprise
                                    }    
                                
                            }

                            if (configuracoes.type == "FINALIDADE" && configuracoes.is_active == 1 &&  configuracoes.id == config.config_id) {
                                enterprise.finalidade = configuracoes.name
                                let isDuplicate = this.enterprises.includes(this.enterprises[index]);   
                                console.log(isDuplicate);                     
                                    if (isDuplicate == false) {                                
                                        this.enterprises[this.enterprises.length] = enterprise
                                    }    
                            }


                         
                        }
                      
                       
                    });
               // });  
               
                  
              
                
                              
                      let isDuplicate = this.enterprises.includes(this.enterprises[index]);   
                      console.log(isDuplicate);                     
                        if (isDuplicate == false) {                                
                            this.enterprises[this.enterprises.length] = enterprise   
                        }                               
                  
            });
            console.log(this.enterprises);






        
                 let suites=[]
        this.enterprises.forEach(element => {
            element.configs.forEach(element2 => {
                if(element2.config.type=="SUITE"){
                     if(element2.config !=null){
                    suites[suites.length]= { 
                        id:element2.config.id, name: element2.config.name 
                        }
                     }
                }
            });
        });
        this.enterprise_suites=suites


    
                 let finalidades2=[]
        this.enterprises.forEach(element => {
            element.configs.forEach(element2 => {
               
                if(element2.config.type=="FINALIDADE"){
                    if(element2.config !=null){
                    finalidades2[finalidades2.length]= { 
                        id:element2.config.id, name: element2.config.name 
                        }
                    }
                }
            });
        });
        console.log(finalidades2);
        this.enterprise_finalidades=finalidades2





           let diferences2=[]
        this.enterprises.forEach(element => {
            element.configs.forEach(element2 => {
               
                if(element2.config.type=="DIFFERENCES"){
                    if(element2.config !=null){
                    diferences2[diferences2.length]= { 
                        id:element2.config.id, name: element2.config.name 
                        }
                    }
                }
            });
        });
   
        this.enterprise_diferences2=diferences2

               
    
  
               this.enterprise_config_metragem = resp[1].filter((configuracoes) => {
                    if (configuracoes.type == "METRAGEM"  && configuracoes["is_active"]==1) {
                        return configuracoes
                    }
                });

                   this.enterprise_config_status = resp[1].filter((configuracoes) => {
                    if (configuracoes.type == "STATUS"  && configuracoes["is_active"]==1) {
                        return configuracoes
                    }
                });


                   this.enterprise_suites = resp[1].filter((configuracoes) => {
                    if (configuracoes.type == "SUITE" && configuracoes["is_active"]==1 ) {
                        return configuracoes
                    }
                });
         
         
         this.enterprise_diferences = resp[1].filter((configuracoes) => {
                    if (configuracoes.type == "DIFERENCES"  && configuracoes["is_active"]==1) {
                        return configuracoes
                    }
                });


             
                this.enterprise_config_finalidade = resp[1].filter((configuracoes) => {
                    if (configuracoes["is_active"]==1 && configuracoes.type == "FINALIDADE" ) {
                        return configuracoes
                    }
                });
                 let metragens  = []
                this.enterprise_config_metragem.forEach((metragem) => {
                    metragens[metragens.length] = parseInt(metragem.name)
                })
        
                this.enterprise_config_metragem = metragens

        
                let finalidades = []
                this.enterprise_config_finalidade.forEach((finalidade) => {
                   finalidades[finalidades.length] =   {name: finalidade.name, id: finalidade.id }
                })

                this.enterprise_config_finalidade = finalidades
              
                this.enterprises.forEach((enterprise) => {
            //         this.finalidade[this.finalidade.length] = enterprise.finalidade
            //         this.finalidade = this.finalidade.filter((c, index) => {
            //                              return this.finalidade.indexOf(c) == index;
            //                          });

                    this.enterprise_type[this.enterprise_type.length] = {
                        name: enterprise.type.name,
                        id: enterprise.type.id
                    }

                    
                  this.enterprise_type.filter((c, index) => {
                   
                                  let isDuplicate = this.enterprise_type.includes(this.enterprise_type[index], c.id);
               
                                    let type = []
                                    if (!isDuplicate) {                                
                                        type[type.length] = {
                                            id: c.id, name: c.name
                                        }
                                    }
                                   
                                    this.enterprise_type = type                                  
                               });


    // this.enterprise_suites[this.enterprise_status.length] = {
    //                     id: enterprise.suites.id,
    //                     name: enterprise.suites.name
    //                 }
                 
                           
                //   this.enterprise_suites.filter((c, index) => {
                    
                //     let isDuplicate = this.enterprise_suites.includes(this.enterprise_suites[index], c.id);

                //         let suites = []
                //         if (!isDuplicate) {                                
                //             suites[suites.length] = {
                //                 id: c.id, name: c.name
                //             }
                //         }
                        
                //         this.enterprise_suites = suites                          
                //     });
                  
                    this.enterprise_status[this.enterprise_status.length] = {
                        id: enterprise.status.id,
                        name: enterprise.status.name
                    }
                 
                           
                  this.enterprise_status.filter((c, index) => {
                    
                    let isDuplicate = this.enterprise_status.includes(this.enterprise_status[index], c.id);

                        let status = []
                        if (!isDuplicate) {                                
                            status[status.length] = {
                                id: c.id, name: c.name
                            }
                        }
                        
                        this.enterprise_status = status                               
                    });
                })









    // this.enterprise_diferences[this.enterprise_diferences.length] = {
    //                     id: this.enterprise.diferences.id,
    //                     name: this.enterprise.diferences.name
    //                 }
                 
                           
    //               this.enterprise_diferences.filter((c, index) => {
                    
    //                 let isDuplicate = this.enterprise_diferences.includes(this.enterprise_diferences[index], c.id);

    //                     let suites = []
    //                     if (!isDuplicate) {                                
    //                         suites[diferences.length] = {
    //                             id: c.id, name: c.name
    //                         }
    //                     }
                        
    //                     this.enterprise_diferences = diferences                       
    //                 });
                  








                this.areas[this.areas.length] = this.enterprise_config_metragem 
               
               Array.min = function( array ){
                    return Math.min.apply( Math, array );
                };

                this.min_area = Array.min(this.areas);

                Array.max = function( array ){
                    return Math.max.apply( Math, array );
                };

                this.max_area = Array.max(this.areas);

                let count = 1
                let limite = 0

                // this.min_area = 300
                // this.max_area = 1400
                // this.areas = [300, 400, 500, 600, 800, 1000, 1400]

                for(var i = this.min_area; i < this.max_area - 101; i++){
                    count = count + 1
                    if (count == 50 && limite < 4) {   
                        limite = limite +1
                        this.areaLabels[this.areaLabels.length] = i + 2
                        count = 0
                    }
                }
                console.log(this.areaLabels);
           }); 
       },
    handleResize() {
      // padrão
      this.width = window.innerWidth;
    },
    },
   
    mounted() {
        (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
 this.site=window.location.host

        this.site=window.location.host
        let first_name = ""
        let last_name =""

  
      

        $(document).ready(() => {
            $('.selectsearch').select2({
                "language":{
                    "noResults" : function () { return 'Busca não encontrada'; }
                }                                    
            }); 
            $(".selectsearch").val(this.$data.enterprise_id)
        });

        $(".selectsearch").on("change", ()=> {            
            this.$data.enterprise_id = parseInt($(".selectsearch").val())
        }); 

     window.addEventListener("resize", this.handleResize);
     this.fetchTheme(); 
    this.handleResize();
      
       
    },
    computed: {
    
    isMobile() {
      return this.width <= 968;
    },
        user() {
            return this.$store.getters.getProfile;
        },
    },
    components: {    Carousel,
    Slide,}
}


</script>
<style scoped>
    @media (min-width: 768px) {
        .bottom-tag {
            right: 20px; 
            bottom: 4px;    
            width: 80%;
        
        }
    }

    @media (max-width: 768px) {
        .bottom-tag {
            right: 15px; 
            bottom: 0px;
            width: 89%;
        
        }
    }


input[type=range] {
    /*removes default webkit styles*/
    -webkit-appearance: none;
    
    /*fix for FF unable to apply focus style bug */
    border: 1px solid white;
    
    /*required for proper track sizing in FF*/
    width: 300px;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: var(--color-broker);
    margin-top: -4px;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
}

input[type=range]::-moz-range-track {
    width: 300px;
    height: 5px;
    background: lightgray;
    border: none;
    border-radius: 3px;
}
input[type=range]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: orange;
}

/*hide the outline behind the border*/
input[type=range]:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
}

input[type=range]::-ms-track {
    width: 300px;
    height: 5px;
    
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;
    
    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;

    /*remove default tick marks*/
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
}
input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
}
input[type=range]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: goldenrod;
}
input[type=range]:focus::-ms-fill-lower {
    background: #888;
}
input[type=range]:focus::-ms-fill-upper {
    background: #ccc;
}


    .status{
        background: rgba(52, 52, 52, 0.6);
    border-radius: 30px 0px 0px 0px;
    padding: 15px 25px;
    color: #fff;
    display: inline-block;
    position: absolute;
    bottom: 0%;
    left: 0px;
    font-weight: 600;
    font-size: 13px;
    }
/*.form-control {
    border-radius:0px !important
}*/

.empreendimentos {
  padding: 80px 20px;
}
.empreendimentos h2 {
  color: var(--cor_principal_lp);
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.empreendimentos .card-emp {
  cursor: move;
  position: relative;
  margin-right: 15px;
}
.empreendimentos .card-emp + .card-emp {
  /* margin-right: 15px; */
}
.empreendimentos .card-emp img {
  object-fit: cover;
  /* width: 260px; */
  width: 100%;
  height: 400px;
  /* border-radius: 0px 30px 0px 0px; */
  /* border-radius: 10px; */
  margin-bottom: 20px;
}
.empreendimentos .card-emp .imagem-nome {
  position: relative;
}
.empreendimentos .card-emp span {
  background: rgba(52, 52, 52, 0.6);
  border-radius: 30px 0px 0px 0px;
  padding: 15px 25px;
  color: #fff;
  display: inline-block;
  position: absolute;
  bottom: 20px;
  left: 0px;
  z-index: 999;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
}
.empreendimentos .card-emp p {
  /* margin-top: 30px; */
  color: #4e4e4e;
  font-size: 11px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .empreendimentos {
    min-width: 100%;
    max-width: 100%;
    padding: 40px 20px;
  }
  .empreendimentos h2{
    font-size: 16px;
  }
}

</style>